:root {
	--colors-white: #e7e6fd;
	--colors-light: #162c44;
	--colors-black: #000;
	--colors-medium: #053c5e;
	--colors-highlight: #aafcad;
	--colors-hazard: #fcaaaa;
	--colors-bold: #e7e6fd;
}

body {
	font-family: 'Poppins', sans-serif;
	font-size: 1.2em;
	margin: 0px;
	padding: 0px;
	background: var(--colors-light);
	color: var(--colors-white);
}

.XSvg {
	color: inherit;
	width: 1.5em;
	@apply fill-current;
}

.XControl {
	cursor: pointer;
	border: none;
	padding: 0.4em 0.4em 0em 0.4em;
	border-radius: 5px;
	background: inherit;
	color: inherit;
}

.dismissing {
	@apply opacity-50;
}

.MainScreen {
	display: grid;
	grid-template-columns: 1fr 2fr;
	padding: 0 1.25rem;
	max-width: 1000px;
	margin: auto;
	margin-bottom: 2em;
	background: var(--colors-light);
	color: var(--colors-white);
}

.LoginScreen {
	display: grid;
	grid-template-columns: 1fr 1fr;
	padding: 0 1.25rem;
	max-width: 1000px;
	margin: auto;
	margin-bottom: 2em;
	background: var(--colors-light);
	color: var(--colors-white);
}

.login-title {
	font-size: 1.8em;
	margin-bottom: 0.5em;
}

.login-copy {
	font-size: 1em;
	padding-right: 1em;
}

.login-graphic {
	border-radius: 45px;
	padding: 1em;
}

@media only screen and (max-width: 800px) {
	.MainScreen,
	.LoginScreen {
		grid-template-columns: 1fr;
	}

	.login-graphic {
		display: none;
	}
}

.AppHeader {
	background: var(--colors-medium);
}

.app-header-content {
	display: flex;
	justify-content: space-between;
	padding-top: 1em;
	max-width: 1000px;
	margin: auto;
}

.logo {
	width: 100%;
	padding-left: 0.5em;
}

.app-title {
	margin: 0 0 -0.45em 0;
}

.UserControl {
	font-size: 1em;
	height: 2em;
	align-self: end;
	background: inherit;
	border: none;
	color: var(--colors-white);
}

.UserControl option {
	background: var(--colors-medium);
}

.user-control-username {
	display: none;
}

.NotificationBanner {
	width: 100%;
	height: 2em;
	line-height: 2em;
	background: var(--colors-black);

	@apply text-center;
}

.notification-error {
	background: var(--colors-hazard);
	color: var(--colors-black);
}

.notification-upgrade {
	background: var(--colors-hazard);
	color: var(--colors-black);
}

.notification-success {
	background: var(--colors-highlight);
	color: var(--colors-black);
}

.KeywordSection {
	margin-right: 2em;
}

.keyword-header {
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: var(--colors-bold);
}

.keyword-limit-label {
	font-size: 0.7em;
	opacity: 0.8;
}

.KeywordForm {
	margin: 0.25em 0.25em 2em 0.25em;

	border-color: var(--colors-white);
}

.KeywordItem {
	display: flex;
	border-radius: 5px;
}

.keyword-item-select {
	background: inherit;
	color: inherit;
	font-size: inherit;
	border: none;
	border-radius: 5px;
	display: flex;
	justify-content: space-between;
	text-align: left;
	cursor: pointer;
	width: 100%;

	@apply p-2;
}

.keyword-item-select:hover {
	background: var(--colors-medium);
	color: var(--colors-white);
}

.keyword-item-select:active {
	border-style: none;
}

.hidden {
	@apply opacity-0;
}

.highlight {
	color: var(--colors-highlight);
}

.dirty {
	color: var(--colors-highlight);
}

.dirty::after {
	content: '•';
}

.KeywordItem.selected {
	background: var(--colors-medium);
	color: var(--colors-white);
}

.new-keyword-form {
	text-transform: lowercase;
	border-bottom: solid 0.05em;
	width: 100%;
}

.new-keyword-note {
	font-style: italic;
	font-size: 0.6em;
}

.filter-control-header {
	color: var(--colors-bold);
}

.FilterForm {
	margin-bottom: 1em;
}

.filter-header-and-type {
	text-transform: capitalize;
}

.filter-input-section {
	border-bottom: solid 0.05em;
	border-color: var(--colors-white);
}

.filter-type-dropdown {
	color: inherit;
	font-size: inherit;
	background: inherit;
	@apply ml-2;
}

.filter-type-dropdown option {
	background: var(--colors-medium);
}

.FilterTags {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 2em;
}

.FilterTag {
	margin: 5px;
	padding-left: 10px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	width: fit-content;
}

.blacklist-tag {
	background: var(--colors-black);
	color: var(--colors-white);
}

.blacklist-highlighted-tag {
	background: var(--colors-black);
	color: var(--colors-highlight);
}

.whitelist-tag {
	background: var(--colors-white);
	color: var(--colors-black);
}

.whitelist-highlighted-tag {
	background: var(--colors-highlight);
	color: var(--colors-black);
}

.filter-section-header {
	color: var(--colors-bold);
}

.FilterSaveControls {
	display: inline-flex;
	margin-left: 0.5em;
	font-size: 0.8em;
}

.login-control {
	text-align: center;
	border-style: outset;
	text-decoration-color: black;
	background: var(--colors-highlight);
	color: var(--colors-black);
}

.login-control:active {
	border-style: inset;
}

.keyword-control,
.login-control {
	cursor: pointer;
	display: inline-block;
	width: 100%;
	font-size: inherit;
	border-radius: 5px;
	padding: 0.4rem;
	margin-right: 0.5em;
	@apply opacity-70;
}

.keyword-control[disabled] {
	cursor: inherit;
}

.keyword-control:hover {
	@apply opacity-100;
}

.save-control {
	background: var(--colors-black);
	color: var(--colors-highlight);
}

.reset-control {
	background: var(--colors-black);
	color: var(--colors-white);
}

.remove-cancel {
	background: var(--colors-medium);
	color: var(--colors-white);
}

.remove-confirm {
	background: var(--colors-hazard);
	color: var(--colors-black);
}

.remove-start {
	background: var(--colors-medium);
	color: var(--colors-hazard);
}

.filter-type-label {
	text-decoration: underline;
}

select,
option {
	cursor: pointer;
}

input {
	color: inherit;
	background: inherit;
	font-size: inherit;
	border: none;
	outline: none;
}

input::placeholder {
	color: inherit;
}

a {
	color: inherit;
}
